@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Sharp Grotesk";
  src: url("assets/fonts/SharpGrotesk-Light20.otf") format("opentype");
  font-weight: 300; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Sharp Grotesk";
  src: url("assets/fonts/SharpGrotesk-Book20.otf") format("opentype");
  font-weight: 400; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Sharp Grotesk";
  src: url("assets/fonts/SharpGrotesk-Medium20.otf") format("opentype");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "Sharp Grotesk";
  src: url("assets/fonts/SharpGrotesk-SemiBold20.otf") format("opentype");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "Sharp Grotesk";
  src: url("assets/fonts/SharpGrotesk-SemiBold20.otf") format("opentype");
  font-weight: 900; /* Bolder */
  font-style: normal;
}

* {
  font-family: "Sharp Grotesk", sans-serif !important;
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}
